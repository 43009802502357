import lazySizes from 'lazysizes';


// Adjusting the classnames in compliance with SUIT
lazySizes.cfg.lazyClass = 'is-lazyLoad';
lazySizes.cfg.preloadClass = 'is-lazyPreload';
lazySizes.cfg.loadingClass = 'is-lazyLoading';
lazySizes.cfg.loadedClass = 'is-lazyLoaded';
// To avoid loading stuff with calculated v:h/opacity:0
lazySizes.cfg.loadHidden = false;
